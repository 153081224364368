<template>
  <v-row class="pa-0">
    <v-col cols="12" class="max-width">
      <v-row>
        <v-col cols="12" class="text-h6 font-weight-light"> Endereço </v-col>
        <v-col cols="12" class="pl-0">
          <v-row class="ma-0">
            <v-col class="pb-0" cols="12" md="4" lg="4">
              <v-row>
                <v-col cols="12" class="pb-0">
                  <div class="">CEP <span class="red--text">*</span></div>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    clearable
                    outlined
                    required
                    placeholder="000.000-0"
                    v-mask="'#####-###'"
                    v-model="dataForm.cep"
                    @blur="onChangeCEP(dataForm.cep)"
                    :rules="[(v) => !!v || 'CEP é obrigatório']"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="pb-0" cols="12" md="4" lg="4">
              <v-row>
                <v-col cols="12" class="pb-0">
                  <div class="">Endereço <span class="red--text">*</span></div>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    disabled
                    clearable
                    outlined
                    required
                    placeholder="Endereço do paciente"
                    counter="100"
                    v-model="dataForm.address"
                    :rules="[(v) => !!v || 'Endereço é obrigatório']"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="pb-0" cols="12" md="4" lg="4">
              <v-row>
                <v-col cols="12" class="pb-0">
                  <div class="">Estado <span class="red--text">*</span></div>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    disabled
                    clearable
                    outlined
                    required
                    placeholder="Estado"
                    counter="50"
                    v-model="dataForm.uf"
                    :rules="[(v) => !!v || 'Estado é obrigatório']"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="pb-0" cols="12" md="4" lg="4">
              <v-row>
                <v-col cols="12" class="pb-0">
                  <div class="">Cidade <span class="red--text">*</span></div>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    disabled
                    clearable
                    outlined
                    required
                    placeholder="Cidade"
                    counter="50"
                    v-model="dataForm.city"
                    :rules="[(v) => !!v || 'Cidade é obrigatório']"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="pb-0" cols="12" md="4" lg="4">
              <v-row>
                <v-col cols="12" class="pb-0">
                  <div class="">Número <span class="red--text">*</span></div>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    clearable
                    outlined
                    required
                    placeholder="00"
                    v-mask="'#####'"
                    v-model="dataForm.house_number"
                    :rules="[(v) => !!v || 'Número é obrigatório']"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="pb-0" cols="12" md="4" lg="4">
              <v-row>
                <v-col cols="12" class="pb-0">
                  <div class="">Bairro <span class="red--text">*</span></div>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    disabled
                    clearable
                    outlined
                    required
                    placeholder="Bairro"
                    counter="50"
                    v-model="dataForm.district"
                    :rules="[(v) => !!v || 'Bairro é obrigatório']"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="pb-0" cols="12" sm="12" md="4">
              <v-row>
                <v-col cols="12" class="pb-0">
                  <div class="">
                    Telefone Residencial
                    <span class="grey--text"> * opcional </span>
                  </div>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    placeholder="(00) 0 0000-0000"
                    clearable
                    outlined
                    v-model="dataForm.phone"
                    v-mask="'(##) # ####-####'"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="pb-0" cols="12" sm="12" md="8">
              <v-row>
                <v-col cols="12" class="pb-0">
                  <div class="">
                    Complemento <span class="grey--text"> * opcional</span>
                  </div>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    clearable
                    outlined
                    required
                    counter="50"
                    placeholder="Complemento, edifício, ponto de referência..."
                    v-model="dataForm.complement"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-card-actions>
        <v-btn text @click="$emit('update:step', Number(step) - 1)">
          <v-icon>mdi-menu-left</v-icon> Voltar
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-if="dataForm.cep && dataForm.house_number"
          @click="$emit('update:step', Number(step) + 1)"
          class="primary"
        >
          Próximo
        </v-btn>
        <v-btn v-else disabled> Próximo </v-btn>
      </v-card-actions>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: ["data", "step", "formValidate", "loading"],
  data: () => ({
    address: "",
    city: "",
    district: "",
    uf: "",
  }),
  methods: {
    async onChangeCEP(cep) {
      try {
        const response = await this.$axios.get(
          "https://viacep.com.br/ws/" + cep + "/json/"
        );
        this.address = response.data.logradouro;
        this.district = response.data.bairro;
        this.city = response.data.localidade;
        this.uf = response.data.uf;

        this.dataForm.address = this.address;
        this.dataForm.district = this.district;
        this.dataForm.city = this.city;
        this.dataForm.uf = this.uf;
      } catch (error) {
        const msg = error.response.error;
        // console.log("Erro ao pegar CEP: ", error);
        this.$swal.fire({
          title: "Erro ao pegar CEP",
          text: "Verifique se o CEP está correto" + msg,
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
    },
  },
  computed: {
    dataForm: {
      get() {
        return this.data || {};
      },
      set(address) {
        return this.$emit("update:data", address);
      },
    },
  },
  mounted() {
    if (this.dataForm.cep) {

      this.onChangeCEP(this.dataForm.cep);
    }
  },
};
</script>

<style></style>