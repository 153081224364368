<template>
  <v-app>
    <v-card class="elevation-0">
      <v-card-text>
        <v-row>
          <v-col cols="12" class="text-h5 pb-0">
            {{
              data
                ? data[step - 1].type
                : formUpdate[0]
                ? formUpdate[0].name
                : "Nenhum formulário disponível"
            }}
          </v-col>
          <v-col cols="12" class="pt-0 pb-0">
            <v-subheader class="pa-0 ma-0 grey--text">
              {{
                $route.params.form_application_id
                  ? "Edição de anamnese"
                  : "Nova anamnese"
              }}
            </v-subheader>
          </v-col>
          <v-col cols="12">
            <v-stepper
              :key="data ? data.length : form.length"
              alt-labels
              v-model="step"
              class="elevation-0"
            >
              <v-stepper-header
                style="background: #f3f3f3"
                class="d-flex justify-start align-center elevation-0"
              >
                <div
                  v-for="(item, index) in data ? data : formUpdate"
                  :key="index"
                >
                  <v-hover v-slot="{ hover }">
                    <v-stepper-step
                      :key="index"
                      class="pointer"
                      :class="{ hover: hover }"
                      :step="index + 1"
                      :complete="step > index + 1"
                      :color="step > index + 1 ? 'green' : '#838181'"
                      @click="step = index + 1"
                    >
                      {{ data ? item.type : item.name }}
                    </v-stepper-step>
                  </v-hover>
                </div>
              </v-stepper-header>
              <v-stepper-items>
                <v-form
                  ref="formAnamneseAnswer"
                  v-model="validForm"
                  lazy-validation
                >
                  <v-stepper-content
                    class="elevation-0"
                    v-for="(item, index) in data ? data : form"
                    :key="index"
                    :step="index + 1"
                  >
                    <v-card>
                      <v-card-text class="pl-0">
                        <v-row>
                          <v-col
                            v-for="(formItem, key) in item.form_items"
                            :key="key"
                            cols="12"
                            class="pb-0 pt-0"
                          >
                            <v-row>
                              <v-col cols="12" class="text-h6 black--text pb-0">
                                {{
                                  formItem.name
                                    .replace("?", "")
                                    .replace(":", "")
                                }}:
                                <span
                                  :class="{
                                    'red--text': formItem.required,
                                    'grey--text': !formItem.required,
                                  }"
                                  >*</span
                                >
                              </v-col>
                              <v-col
                                cols="12"
                                class="pt-0"
                                v-if="formItem.kind === 'radio'"
                              >
                                <v-radio-group
                                  v-if="
                                    answersAsHash[formItem.id] &&
                                    answersAsHash[formItem.id].config
                                  "
                                  v-model="answersAsHash[formItem.id].content"
                                  row
                                >
                                  <v-radio
                                    v-for="(item, index) in answersAsHash[
                                      formItem.id
                                    ].config.items"
                                    :key="index"
                                    class=""
                                    :label="item.text"
                                    :value="item.text"
                                  ></v-radio>
                                </v-radio-group>
                              </v-col>
                              <v-col
                                v-if="formItem.kind === 'string'"
                                cols="12"
                                class=""
                              >
                                <v-text-field
                                  outlined
                                  dense
                                  placeholder="Responder"
                                  v-model="answersAsHash[formItem.id].content"
                                  :type="formItem.kind"
                                  v-if="answersAsHash[formItem.id]"
                                  :rules="
                                    formItem.required
                                      ? [(v) => !!v || 'Campo obrigatório']
                                      : []
                                  "
                                  :required="formItem.required"
                                  :counter="formItem.counter"
                                ></v-text-field>
                              </v-col>
                              <v-col
                                v-if="formItem.kind === 'text'"
                                cols="12"
                                class=""
                              >
                                <v-textarea
                                  outlined
                                  dense
                                  placeholder="Responder"
                                  v-model="answersAsHash[formItem.id].content"
                                  v-if="answersAsHash[formItem.id]"
                                  :rules="
                                    formItem.required
                                      ? [(v) => !!v || 'Campo obrigatório']
                                      : []
                                  "
                                  :required="formItem.required"
                                  :counter="formItem.counter"
                                ></v-textarea>
                              </v-col>
                              <v-col
                                cols="12"
                                v-if="formItem.kind === 'number'"
                              >
                                <v-text-field
                                  outlined
                                  dense
                                  placeholder="Responder"
                                  v-model="answersAsHash[formItem.id].content"
                                  v-if="answersAsHash[formItem.id]"
                                  :type="formItem.kind"
                                  :required="formItem.required"
                                  :counter="formItem.counter"
                                  :rules="
                                    formItem.required
                                      ? [(v) => !!v || 'Campo obrigatório']
                                      : []
                                  "
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" v-if="formItem.kind === 'date'">
                                <v-menu
                                  ref="datePickerModal"
                                  v-model="datePickerModal"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  v-if="answersAsHash[formItem.id]"
                                  offset-y
                                  max-width="290px"
                                  min-width="auto"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      v-model="
                                        answersAsHash[formItem.id].content
                                      "
                                      placeholder="MM/DD/YYYY"
                                      hint="MM/DD/YYYY format"
                                      persistent-hint
                                      prepend-inner-icon="mdi-calendar"
                                      outlined
                                      dense
                                      v-bind="attrs"
                                      @blur="
                                        answersAsHash[formItem.id].content =
                                          parseDate(
                                            answersAsHash[formItem.id].content
                                          )
                                      "
                                      v-on="on"
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-if="answersAsHash[formItem.id]"
                                    :value="
                                      answersAsHash[formItem.id] &&
                                      answersAsHash[formItem.id].content
                                    "
                                    @input="
                                      formItemAnswers.push({
                                        form_item_id: formItem.id,
                                        form_application_id:
                                          $route.params.form_application_id,
                                        user_id: $route.params.id,
                                        content: $event || '',
                                      });
                                      datePickerModal = false;
                                    "
                                    no-title
                                  ></v-date-picker>
                                </v-menu>
                              </v-col>
                              <v-col
                                cols="12"
                                class="pt-0"
                                v-if="formItem.kind === 'multiselect'"
                              >
                                <!-- formItemAnswers.push({form_item_id: formItem.id, content_multiple: $event, form_application_id: $route.params.form_application_id, user_id: $route.params.id, content: $event ? $event.join(', ') : null}) -->
                                <v-select
                                  outlined
                                  dense
                                  placeholder="Selecione"
                                  :items="
                                    answersAsHash[formItem.id] &&
                                    answersAsHash[formItem.id].config.items
                                  "
                                  :value="
                                    answersAsHash[formItem.id] &&
                                    answersAsHash[formItem.id].content_multiple
                                  "
                                  @input="
                                    updateSelectResponses($event, formItem.id)
                                  "
                                  v-show="answersAsHash[formItem.id]"
                                  multiple
                                  :rules="
                                    formItem.required
                                      ? [(v) => !!v || 'Campo obrigatório']
                                      : []
                                  "
                                  :required="formItem.required"
                                >
                                  <template v-slot:prepend-item>
                                    <v-row class="pa-2">
                                      <v-col cols="10" sm="11">
                                        <v-text-field
                                          v-model="newMultiselectItem"
                                          outlined
                                          dense
                                          placeholder="Novo item"
                                        ></v-text-field>
                                      </v-col>
                                      <v-col cols="2" sm="1">
                                        <v-btn
                                          width="100%"
                                          @click="addItemMultselect(
                                              newMultiselectItem,
                                              formItem.id
                                            )
                                          "
                                          text
                                        >
                                          <v-icon large>mdi-plus</v-icon>
                                        </v-btn>
                                      </v-col>
                                    </v-row>
                                  </template>
                                </v-select>
                              </v-col>
                              <v-col cols="12" v-if="formItem.kind === 'imc'">
                                <imcAnswer
                                  @content="
                                    bmiResult(
                                      $event,
                                      answersAsHash[formItem.id]
                                    )
                                  "
                                />
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-actions class="">
                        <v-btn v-if="step > 1" text @click="step = step - 1"
                          >Voltar</v-btn
                        >
                        <v-spacer></v-spacer>
                        <v-btn
                          @click="onAnswerAnamnesis()"
                          :loading="loading"
                          class="primary"
                          :disabled="
                            data
                              ? step !== data.length
                                ? false
                                : !validForm
                              : step !== form.length
                              ? false
                              : !validForm
                          "
                        >
                          {{ step !== data.length ? "Próximo" : "Salvar" }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-stepper-content>
                </v-form>
              </v-stepper-items>
            </v-stepper>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import imcAnswer from "../../../Questions/ImcAnswer.vue";

export default {
  components: {
    imcAnswer,
  },
  props: {
    data: {
      required: true,
    },
    loading: {
      required: true,
      type: Boolean,
    },
    patient: {
      required: true,
      type: Object,
    },
  },
  data: () => ({
    step: 1,
    form: [],
    formItems: [],
    formItemAnswers: [],
    validForm: true,
    newMultiselectItem: [],
  }),
  mounted() {
    if (this.data && this.data.length > 0) {
      this.data.forEach((el) => {
        el.form_items.forEach((item) => (item.form_id = el.id));
        this.formItems = [...this.formItems, ...el.form_items];
      });
    }
  },
  methods: {
    addItemMultselect(item, id) {
      if(item && item != ''){
        this.answersAsHash[id].config.items.unshift(item);
      }
      item = null;
    },
    updateSelectResponses(event, formItemId) {
      // console.log(event);

      if (this.answersAsHash[formItemId]) {
        this.answersAsHash[formItemId].content_multiple = event;
      } else {
        this.formItemAnswers.push({
          form_item_id: formItemId,
          content_multiple: event,
          form_application_id: this.$route.params.form_application_id,
          user_id: this.$route.params.user_id,
          content: event ? event.join(", ") : null,
        });
      }
    },
    async onAnswerAnamnesis() {
      try {
        if (this.step !== this.data.length) return (this.step = this.step + 1);

        if (!this.$refs.formAnamneseAnswer.validate()) {
          this.$swal({
            position: "center",
            showConfirmButton: true,
            icon: "error",
            title: "MIS",
            text: "Por favor, preencha os campos obrigatórios.",
          });

          this.step = 1;
          return;
        }

        this.$emit("on:create", this.answersAsHash);
      } catch (error) {
        // console.log("OnAnswerAnamnesis error: ", error);

        this.$swal({
          position: "center",
          showConfirmButton: true,
          icon: "error",
          title: "MIS",
          text: "Ocorreu um erro ao tentar salvar as respostas do formulário.",
        });
      }
    },
    async showFormApplication() {
      try {
        const response = await this.$axios(
          "form_applications/" + this.$route.params.form_application_id,
          {
            params: {
              user_id: this.$route.params.id,
            },
          }
        );

        // Adds the responses received by the form application in their appropriate form items
        response.data.form.form_items.forEach((formItem) => {
          formItem.form_item_answers = response.data.form_item_answers.filter(
            (itemAnswer) => itemAnswer.form_item_id == formItem.id
          );
        });

        this.formUpdate = response.data.form;

        this.formUpdate.forEach((el) => {
          this.formItems = [...this.formItems, ...el.form_items];
        });
      } catch (error) {
        const msg = error.response.error;

        this.$swal({
          toast: false,
          position: "center",
          showConfirmButton: true,
          icon: "error",
          title: "MIS",
          text:
            "Ocorreu um erro ao tentar carregar o formulário anamnese." + msg,
        });
      }
    },
    setMask(mask) {
      const masks = {
        phone: "(##) # ####-####",
        cpf: "###.###.###-##",
        rg: "#.###.###",
        date: "##/##/####",
      };

      return String(masks[mask]) || "";
    },
    parseDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    bmiResult(value, result) {
      result.content = value;
    },
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.authentication.currentUser,
    }),
    answersAsHash() {
      if (this.formItemAnswers && this.formItemAnswers.length) return {};

      const response = this.formItemAnswers.reduce(
        (prev, current) => (prev[current.form_item_id] = current),
        {}
      );

      this.formItems.forEach((item) => {
        response[item.id] ||= {
          id:
            item.form_item_answers && item.form_item_answers.length
              ? item.form_item_answers[0] && item.form_item_answers[0].id
              : null,
          form_item_id: item.id,
          config: item.config || null,
          mask: item.mask || "",
          counter: item.counter,
          content_multiple: item.content_multiple || [],
          content: this.$route.params.form_application_id
            ? item.form_item_answers && item.form_item_answers.length
              ? item.form_item_answers[0].content
              : item.config?.value
            : item.config?.value,
        };
      });

      return response;
    },
    datePickerModal: {
      get() {
        return false;
      },
      set(res) {
        return res;
      },
    },
    formUpdate: {
      get() {
        return this.form || [];
      },
      set(form) {
        return (this.form = [form]);
      },
    },
  },
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
.hover:hover {
  filter: brightness(80%);
}
</style>
