<template>
  <v-row>
    <!-- <v-col cols="12" class="pl-2 pb-0">
      <v-btn text class="pa-2" @click="$router.go(-1)">
        <v-icon>mdi-chevron-left</v-icon>
        Voltar
      </v-btn>
    </v-col> -->
    <v-col cols="12" class="text-h6 black--text pb-0"> Nova anamnese </v-col>
    <v-col cols="12" class="caption pt-0">
      <v-subheader class="pl-0 pt-0">
        Selecione abaixo a nova anamnese desejada
      </v-subheader>
    </v-col>
    <v-col cols="12" class="pt-0 pb-0">
      <v-form ref="formAnamneseAnswer" v-model="validForm">
        <v-row>
          <v-col cols="12" sm="8" md="10" lg="10">
            <v-select
              placeholder="Selecione um formulário"
              :items="anamneses"
              item-text="type"
              return-object
              outlined
              dense
              v-model="currentAnamnese"
              :item-disabled="
                (item) => selectedAnamneses.some((e) => e.id === item.id)
              "
            ></v-select>
          </v-col>
          <v-col cols="12" sm="4" md="2" lg="2">
            <v-hover v-slot="{ hover }">
              <v-btn
                :disabled="!currentAnamnese"
                height="40"
                outlined
                width="100%"
                :class="{ 'black--text': hover, 'grey--text': !hover }"
                @click="onInsetItem(currentAnamnese)"
                >Adicionar
              </v-btn>
            </v-hover>
          </v-col>
        </v-row>
      </v-form>
    </v-col>
    <v-col cols="12" class="body-1">Adicionados:</v-col>
    <v-col
      cols="12"
      class="pt-0 pl-2"
      v-for="(item, index) in selectedAnamneses"
      :key="index"
    >
      <v-card
        v-if="
          (item.config.public == 'female' && patient.sex == '0') ||
          (item.config.public == 'male' && patient.sex == '1') ||
          item.config.public == 'both'
        "
        class="elevation-0"
      >
        <v-card-text>
          <v-row class="">
            <v-col cols="12" class="selected">
              <v-row class="align-center">
                <v-col cols="8" sm="10" class="align-end black--text">{{
                  item.type
                }}</v-col>
                <v-col cols="4" sm="2">
                  <v-btn
                    width="100%"
                    color="red"
                    class="white--text"
                    @click="onDeleteItem(item)"
                    >Excluir</v-btn
                  >
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <!-- <v-col cols="12" class="d-flex justify-end">
      <v-btn
        :disabled="selectedAnamneses.length === 0"
        :width="$vuetify.breakpoint.mobile ? '100%' : 'auto'"
        class="primary"
        @click="$emit('change', selectedAnamneses)"
        >Iniciar anamnese</v-btn
      >
    </v-col> -->
  </v-row>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    patient: {
      required: true,
      type: Object,
    },
  },
  data: () => ({
    anamneses: [],
    currentAnamnese: null,
    selectedAnamneses: [],
    otherAnamneses: [],
    validForm: true,
    createNotification: {
      title: "",
      description: "",
    },
    createNotificationModal: false,
    createdNotificationModal: false,
    titleNotificationRules: [(v) => !!v || "Título é obrigatório"],
    descriptionNotificationRules: [(v) => !!v || "Descrição é obrigatório"],
    validateFormNotification: false,
    openDeletePatient: false,
  }),
  mounted() {
    this.showAnamneseForms();
  },
  methods: {
    async showAnamneseForms() {
      try {
        const response = await this.$axios("forms");
        if (response.data) {
          response.data.map((anamnese) => {
            const uniqueAnamneses = this.anamneses.some(
              (el) => el.id === anamnese.id
            );

            if (!uniqueAnamneses) {
              if (anamnese?.config?.public != "both") {
                this.otherAnamneses.push({
                  id: anamnese.id,
                  type: anamnese.name,
                  date: this.formatLastUpdate(anamnese.updated_at),
                  required: anamnese.required,
                  config: anamnese.config,
                  form_items: anamnese.form_items,
                });
              } else {
                if (anamnese.required) {
                  this.selectedAnamneses.push({
                    id: anamnese.id,
                    type: anamnese.name,
                    date: this.formatLastUpdate(anamnese.updated_at),
                    required: anamnese.required,
                    config: anamnese.config,
                    form_items: anamnese.form_items,
                  });
                }

                this.anamneses = [
                  {
                    id: anamnese.id,
                    type: anamnese.name,
                    date: this.formatLastUpdate(anamnese.updated_at),
                    required: anamnese.required,
                    config: anamnese.config,
                    form_items: anamnese.form_items,
                  },
                  ...this.anamneses,
                ];
              }
            }

            this.$emit("change", this.selectedAnamneses);
          });
        }
      } catch (error) {

        // console.log("Show anamnesis error: ", error);
        this.$swal({
          toast: false,
          position: "center",
          showConfirmButton: true,
          icon: "error",
          title: "MIS",
          text: "Ocorreu um erro ao tentar carregar as anamnesis." + error,
        });
      }
    },
    onInsetItem(item) {
      this.$refs.formAnamneseAnswer.validate();

      const uniqueAnamneses = this.selectedAnamneses.some(
        (el) => el.id === item.id
      );

      if (!uniqueAnamneses) this.selectedAnamneses.push(item);
    },
    onDeleteItem(item) {
      this.selectedAnamneses = this.selectedAnamneses.filter(
        (el) => el.id !== item.id
      );

      this.$emit("change", this.selectedAnamneses);
    },
    formatLastUpdate(date) {
      const day = date.slice(8, 10);
      const month = date.slice(5, 7);
      const year = date.slice(0, 4);
      const time = date.slice(11, 19);

      return `${day}/${month}/${year} às ${time}`;
    },
    onCloseCreateNotification() {
      this.createNotificationModal = false;
      this.$refs.formNotification.reset();
    },
    onChangeAnamnesis(type) {
      const anamnesis = this.otherAnamneses.filter(
        (anamnese) => anamnese?.config?.public == type
      );

      if (this.patient.sex == 0) {
        this.selectedAnamneses = this.selectedAnamneses.filter(
          (anamnese) => anamnese?.config?.public != "male"
        );
        this.anamneses = this.anamneses.filter(
          (anamnese) => anamnese?.config?.public != "male"
        );
      }

      if (this.patient.sex == 1) {
        this.selectedAnamneses = this.selectedAnamneses.filter(
          (anamnese) => anamnese?.config?.public != "female"
        );

        this.anamneses = this.anamneses.filter(
          (anamnese) => anamnese?.config?.public != "female"
        );
      }

      this.selectedAnamneses = [...this.selectedAnamneses, ...anamnesis];
      this.anamneses = [...this.anamneses, ...anamnesis];

      this.$emit("change", this.selectedAnamneses);
    },
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.authentication.currentUser,
    }),
  },
  watch: {
    "patient.sex"(val) {
      const sex = val == 0 ? "female" : "male";

      this.onChangeAnamnesis(sex);
    },
  },
};
</script>

<style scoped>
.selected {
  background: #f5f5f5;
}
</style>
