<template>
  <!-- <imc-input :content.sync="answersAsHash[formItem.id].content"> -->
  <v-row>
    <v-col cols="4">
      <v-text-field
        class="mt-2"
        v-model="height"
        label="Digite sua altura em centímetros"
        type="number"
        min="0"
        outlined
        dense
        @keyup="bmiCalculate"
      ></v-text-field>
    </v-col>
    <v-col cols="4">
      <v-text-field
        class="mt-2"
        v-model="mass"
        label="Digite o peso em kg"
        min="0"
        outlined
        dense
        type="number"
        @keyup="bmiCalculate"
      ></v-text-field>
    </v-col>
    <v-col cols="4">
      <v-card height="4vw" outlined elevation="0">
        <div class="container" v-if="bmi === null">
          <p class="bmi">Digite a altura e o peso !</p>
        </div>
        <div class="container" v-else-if="bmi < 16">
          <p class="bmi">Grau de Desnutrição: {{ bmi }}</p>
        </div>
        <div class="container" v-else-if="bmi < 18">
          <p class="bmi">Grau de Magreza: {{ bmi }}</p>
        </div>
        <div class="container" v-else-if="bmi < 25">
          <p class="bmi">Grau de Normalidade com Altura: {{ bmi }}</p>
        </div>
        <div class="container" v-else-if="bmi < 30">
          <p class="bmi">Grau de Sobrepeso: {{ bmi }}</p>
        </div>
        <div class="container" v-else-if="bmi > 30">  
          <p class="bmi">Grau de Obesidade: {{ bmi }}</p>
        </div>
        <div class="container" v-else-if="bmi > 40">
          <p class="bmi">Grau de Obesidade Mórbida: {{ bmi }}</p>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "imcAnswer",
  props: {
    imc: {
      type: Object,
    },
  },
  data: () => ({
    height: null,
    mass: null,
    bmi: null,
  }),
  methods: {
    bmiCalculate() {
      var m = parseInt(this.mass);
      var h = parseInt(this.height) / 100;
      var r = m / (h * h);
      if (!this.mass || !this.height) return;
      this.bmi = r.toFixed(2);
      this.$emit("content", this.bmi);
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  text-align: center;
}
.bmi {
  font-size:1vw;
}
</style>
