<template>
  <v-row justify="center">
    <v-dialog
      persistent
      :value="open"
      @input="$emit('update:open', $event)"
      max-width=""
    >
      <v-card>
        <v-card-title style="word-break: break-word" class="pl-13 pr-13 text-h6 font-weight-light text-center">
          {{ title }}
        </v-card-title>

        <v-card-text style="word-break: break-word" class="text-center">
          <v-icon :size="iconSize" :color="iconColor">{{ description }}</v-icon>
        </v-card-text>

        <v-card-actions class="flex-column">
          <v-spacer></v-spacer>

          <v-btn
            width="100%"
            class="white--text pa-5"
            :color="colorButton || '#1C3B7B'"
            @click="onPressAgree"
          >
            {{ titleButton }}
          </v-btn>

          <v-btn
            width="100%"
            class="caption font-weight-light pa-5"
            color="#323F4B"
            text
            @click="onClose"
          >
            {{ titleCloseButton || "Voltar" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: [
    "open",
    "title",
    "description",
    "titleButton",
    "colorButton",
    "titleCloseButton",
    "height",
    "width",
    "iconSize",
    "iconColor",
  ],
  methods: {
    onPressAgree() {
      this.$emit("accepted");
    },
    onClose() {
      this.$emit("close");
    },
  },
};
</script>

<style></style>
