<template>
  <v-row class="pa-2" justify="center">
    <overlay :loading="loading" />
    <simple-buttonless-alert
      :open="openCreatedPatient"
      title="Paciente cadastrado com sucesso!"
      width="335px"
      titleButton="Ver perfil do paciente"
      titleCloseButton="Ir para dashboard"
      description="mdi-check-circle"
      iconSize="100"
      iconColor="#47A30E"
      @accepted="onCloseCreatedPatientDialog(lastPatientCreatedId)"
      @close="onCloseCreatedPatientDialog(null)"
    />
    <simple-buttonless-alert
      :open="updatedPatientDialog"
      title="Paciente editado com sucesso!"
      width="335px"
      titleButton="Ver perfil do paciente"
      titleCloseButton="Ir para dashboard"
      description="mdi-check-circle"
      iconSize="100"
      iconColor="#47A30E"
      @accepted="onCloseCreatedPatientDialog(lastPatientCreatedId)"
      @close="onCloseCreatedPatientDialog(null)"
    />
    <v-card width="100%" class="elevation-0">
      <v-card-title>
        <v-row>
          <v-col cols="12" class="text-h5 black--text pb-0">{{
            this.$route.params.id ? "Edição de paciente" : "Novo paciente"
          }}</v-col>
          <v-col cols="12" class="caption pt-0 grey--text">{{
            this.$route.params.id
              ? "Edição de paciente"
              : "Cadastro de novo paciente"
          }}</v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form ref="formCreatePatient" v-model="validateForm" lazy-validation>
          <v-stepper alt-labels v-model="step">
            <v-stepper-header class="align-center">
              <v-stepper-step
                :complete="step > 1"
                step="1"
                class="caption"
                editable
              >
                Dados gerais
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step
                editable
                :complete="step > 2"
                step="2"
                class="caption text-center"
              >
                Endereço
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step
                :editable="step > 2"
                :complete="step > 3"
                step="3"
                class="caption text-center"
              >
                Contato
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step
                :editable="step > 3"
                :complete="step > 4"
                step="4"
                class="caption text-center"
              >
                Anamnese
              </v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
              <v-stepper-content step="1">
                <v-col cols="12" class="pb-0">
                  <v-row class="">
                    <v-col cols="5" class="pb-0">
                      <v-checkbox
                        value="epr"
                        v-model="patient.bracelet"
                        :label="`Utilizar pulseira 2`"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="7">
                      <v-text-field
                        v-if="patient.bracelet"
                        v-model="link"
                        type="number"
                        placeholder="Digite o ID da pulseira"
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <general-data
                  :loading="loading"
                  :formValidate="!validateForm"
                  :data.sync="patient"
                  @onPressAgree="onPressAgree"
                  :step.sync="step"
                />
              </v-stepper-content>
              <v-stepper-content step="2">
                <address-data
                  :loading="loading"
                  :formValidate="!validateForm"
                  :data.sync="patient.address_attributes"
                  @onPressAgree="onPressAgree"
                  :step.sync="step"
                />
              </v-stepper-content>
              <v-stepper-content step="3">
                <emergency-contact
                  :loading="loading"
                  :formValidate="!validateForm"
                  :data.sync="patient.emergency_contacts_attributes"
                  @onPressAgree="onPressAgree"
                  :step.sync="step"
                />
              </v-stepper-content>
              <v-stepper-content step="4" class="">
                <v-row>
                  <v-col cols="12">
                    <anamnese
                      :patient="patient"
                      @change="(e) => (selectedAnamnesis = e)"
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-divider></v-divider>
                  </v-col>
                  <v-col cols="12">
                    <anamnese-form
                      :data="selectedAnamnesis"
                      :patient="patient"
                      :key="selectedAnamnesis.length"
                      v-if="selectedAnamnesis.length > 0"
                      @on:create="onPressAgree"
                      :loading="loading"
                    />
                  </v-col>
                </v-row>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-form>
      </v-card-text>
    </v-card>
  </v-row>
</template>
<script>
import { mapState } from "vuex";
import simpleButtonlessAlert from "../../../components/Dialogs/simpleButtonlessAlert.vue";
import GeneralData from "../../../components/User/GeneralData.vue";
import AddressData from "../../../components/User/Address.vue";
import EmergencyContact from "../../../components/User/EmergencyContact.vue";
import Anamnese from "../../../components/User/Anamnese/Index.vue";
import AnamneseForm from "../../../components/User/Anamnese/Form/Index.vue";
import Overlay from "../../../components/Overlay/Overlay.vue";

export default {
  components: {
    simpleButtonlessAlert,
    GeneralData,
    AddressData,
    Anamnese,
    AnamneseForm,
    EmergencyContact,
    Overlay,
  },
  data: () => ({
    loading: false,
    step: 1,
    anamneseStep: 1,
    updatedPatientDialog: null,
    lastPatientCreatedId: null,
    openCreatedPatient: false,
    currentPatientId: null,
    loadingForm: false,
    birthDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    link: null,
    patient: {
      bracelet: null,
      name: null,
      cpf: null,
      rg: null,
      age: null,
      email: null,
      password: 123456,
      phone: null,
      comments: null,
      birth_date: null,
      sex: null,
      role_id: 12,
      address_attributes: {
        address: null,
        state: null,
        city: null,
        cep: null,
        house_number: null,
        district: null,
        phone: null,
        complement: null,
        uf: null,
      },
      emergency_contacts_attributes: [
        {
          name: null,
          phone: null,
        },
      ],
    },
    validateForm: false,
    modal: false,
    selectedAnamnesis: [],
  }),
  mounted() {
    if (this.$route.params.id) this.updateCurrentData();
  },
  watch: {
    steps(val) {
      if (this.e1 > val) {
        this.e1 = val;
      }
    },
  },
  methods: {
    async updateCurrentData() {
      this.loading = true;
      try {
        // this.loading = true;
        const response = await this.$axios("users/" + this.$route.params.id);

        if (response.data) {
          delete Object.assign(response.data, {
            ["address_attributes"]: response.data["address"],
          })["address"];
          delete Object.assign(response.data, {
            ["emergency_contacts_attributes"]:
              response.data["emergency_contacts"],
          })["emergency_contacts"];

          if (response.data.sex == "female") response.data.sex = "0";
          if (response.data.sex == "male") response.data.sex = "1";

          this.patient = response.data;
          this.loading = false;
        }
      } catch (error) {
        this.loading = false;
        console.log("Show patient error: ", error),
          this.$swal({
            position: "center",
            showConfirmButton: true,
            icon: "error",
            title: "MIS",
            text: "Ocorreu um error ao tentar carregar o paciente.",
          });
      }
      const user = await this.$axios("users/" + this.$route.params.id);
      const response = user.data;

      if (response.data) this.patient = response.data;
    },
    async onPressAgree(answersAsHash) {
      this.loadingForm = true;
      this.loading = true;
      this.$refs.formCreatePatient.validate();

      if (!this.$refs.formCreatePatient.validate()) {
        this.step = 1;
        this.loading = false;
        return;
      }
      const msg = `https://api.eprevsaude.com.br/bracelet/?token=2bf2a986-e8df-11ec-8fea-0242ac120002&userId=`;

      this.patient.sex = Number(this.patient.sex);
      this.link = this.cpf;
      this.patient.link = msg + this.link;
      // this.patient.age = this.saveAgeFormatted(this.patient.birthDate);

      try {
        const response = await (this.patient.id
          ? this.$axios.put(`users/${this.patient.id}`, { user: this.patient })
          : this.$axios.post("users", { user: this.patient })
        ).catch((res) => {
          if (!res.response.data) return;

          const errors = res.response.data;
          const errorMessages = [];

          for (const i in errors) {
            errorMessages.push(`"${i[0].toUpperCase() + i.substr(1)}"`);
          }
          if (res.response.status === 422) {
            this.$swal({
              position: "center",
              showConfirmButton: true,
              icon: "error",
              title: "MIS",
              text:
                `${errors.length > 1 ? "Campos " : "Campo"} em uso: ` +
                errorMessages.join(", "),
            });
            this.loading = false;
            throw new Error("Error 422");
          } else {
            this.loading = false;
            this.$swal({
              position: "center",
              showConfirmButton: true,
              icon: "error",
              title: "MIS",
              text: "Ocorreu um error ao tentar criar o paciente",
            });
          }
        });

        [...new Set(this.selectedAnamnesis)].map(async (el) => {
          const formApplication = await this.$axios.post("form_applications", {
            form_application: {
              doctor_id: this.currentUser.id,
              form_id: el.id,
            },
          });

          // Add form_application_id and user_id to form_item_answer
          Object.values(answersAsHash).forEach((el) => {
            el.form_application_id = formApplication.data.id;
            el.user_id = response.data.id;
          });

          // Endpoint for create multiple answers in one post
          await this.$axios.post("multiple_form_item_answers", {
            form_item_answer: Object.values(answersAsHash),
          });
        });

        this.loading = false;
        this.lastPatientCreatedId = response.data.id;
        this.openCreatedPatient = true;

        this.$store.dispatch("patients/getPatients");
      } catch (error) {
        const msg = error.response.error;

        this.loading = false;
        this.loadingForm = false;
        this.$swal({
          position: "center",
          showConfirmButton: true,
          icon: "error",
          title: "MIS",
          text: "Ocorreu um erro ao tentar criar o paciente",
          msg,
        });
      }
    },
    onClose() {
      this.$emit("close");
      this.$refs.formCreatePatient.reset();
    },
    saveAgeFormatted(birthDate) {
      var currentDate = new Date();
      this.patient.age = currentDate.getFullYear() - birthDate.slice(0, 4);
    },
    onCloseCreatedPatientDialog(id) {
      this.openCreatedPatient = false;
      if (id == null) {
        this.$router.push({
          path: "/dashboard",
        });

        return;
      }

      this.$router.push({
        path: "/patient/" + id,
        params: {
          id: id,
        },
      });

      this.$refs.formCreatePatient.reset();
    },
    unique(previus, after) {
      const isCurrentSequels = (prev, pos) => prev.name === pos.name;
      const onlyPrevSequels = (prev, pos, compareFunction) =>
        prev.filter(
          (prevValue) =>
            !pos.some((posValue) => compareFunction(prevValue, posValue))
        );

      const onlyBefore = onlyPrevSequels(previus, after, isCurrentSequels);

      const onlyAfter = onlyPrevSequels(after, previus, isCurrentSequels);

      // receives the new values so as not to duplicate existing values
      return [...onlyBefore, ...onlyAfter];
    },
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.authentication.currentUser,
    }),
  },
};
</script>

<style scopped>
.max-width {
  width: 100%;
}
.pointer {
  cursor: pointer;
}
</style>