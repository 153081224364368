<template>
  <v-row class="pa-0">
    <v-col cols="12" class="max-width">
      <v-row>
        <v-col cols="12" class="text-h6 font-weight-light">
          Contato emergencial
        </v-col>
        <v-col
          cols="12"
          class="pl-0 pb-0 pt-0"
          v-for="(item, index) in dataForm"
          :key="index"
        >
          <v-row class="ma-0">
            <v-col cols="12">
              <span class="subtitle-1">
                Em caso de emergência, ligar para:
              </span>
              <v-divider></v-divider>
            </v-col>
            <v-col class="pb-0" cols="12" sm="6">
              <v-row>
                <v-col cols="12" class="pb-0">
                  <div class="">
                    Nome <span class="grey--text"> * opcional</span>
                  </div>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    clearable
                    outlined
                    placeholder="Nome completo do contato"
                    @keypress="blockNumber($event)"
                    counter="100"
                    v-model="item.name"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="pb-0" cols="12" sm="6">
              <v-row>
                <v-col cols="12" class="pb-0">
                  <div class="">
                    Telefone <span class="grey--text"> * opcional</span>
                  </div>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    clearable
                    outlined
                    placeholder="(00) 0 0000-0000"
                    v-mask="'(##) # ####-####'"
                    v-model="item.phone"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="">
          <v-btn
            @click="dataForm.push({ name: null, phone: null })"
            color="primary"
            width="100%"
            height="45"
            outlined
            class=""
          >
            <v-icon class="pr-1">mdi-plus</v-icon>
            Novo contato
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-card-actions>
        <v-btn text @click="$emit('update:step', Number(step) - 1)">
          <v-icon>mdi-menu-left</v-icon> Voltar
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="$emit('update:step', Number(step) + 1)" class="primary">
          Próximo
        </v-btn>
      </v-card-actions>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: ["data", "step", "formValidate", "loading"],
  data: () => ({}),
  methods: {
    blockNumber(e){
      if(e.keyCode > 47 && e.keyCode < 58) e.preventDefault();
    },
  },
  computed: {
    dataForm: {
      get() {
        return this.data || [];
      },
      set(contact) {
        return this.$emit("update:data", contact);
      },
    },
  },
};
</script>

<style></style>
