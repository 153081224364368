<template>
  <v-row class="pa-0">
    <v-col cols="12" class="max-width">
      <v-row>
        <v-col cols="12" class="text-h6 font-weight-light">
          Dados gerais
        </v-col>
        <v-col cols="12" class="pl-0">
          <v-row class="ma-0">
            <v-col class="pb-0" cols="12" md="12" lg="4">
              <v-row>
                <v-col cols="12" class="pb-0">
                  Nome completo <span class="red--text">*</span>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    @keypress="blockNumber($event)"
                    clearable
                    outlined
                    placeholder="Nome completo do paciente"
                    required
                    v-model="dataForm.name"
                    :rules="nameRules"
                    counter="100"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="pb-0" cols="12" md="12" lg="4">
              <v-row>
                <v-col cols="12" class="pb-0">
                  E-mail <span class="red--text">*</span>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    clearable
                    outlined
                    placeholder="exemplo@gmail.com"
                    required
                    v-model="dataForm.email"
                    :rules="[(v) => !!v || 'E-mail é obrigatório']"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="pb-0" cols="12" md="12" lg="4">
              <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="dataForm.birth_date"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-row>
                    <v-col cols="12" class="pr-0 pb-0">
                      Data de nascimento
                      <span class="red--text">*</span>
                    </v-col>
                    <v-col cols="12" class="">
                      <v-text-field
                        v-model="dataForm.birth_date"
                        placeholder="00/00/0000"
                        clearable
                        outlined
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :rules="birthDateRules"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </template>
                <v-date-picker
                  v-model="dataForm.birth_date"
                  scrollable
                  :max="date"
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal = false">
                    Cancelar
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog.save(dataForm.birth_date)"
                  >
                    Selecionar
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col class="pb-0" cols="12" md="4" lg="4">
              <v-row>
                <v-col cols="12" class="pb-0">
                  <div class="">CPF <span class="red--text">*</span></div>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    clearable
                    outlined
                    required
                    placeholder="000.000.000-00"
                    v-mask="'###.###.###-##'"
                    v-model="dataForm.cpf"
                    :rules="cpfRules"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="pb-0" cols="12" md="4" lg="4">
              <v-row>
                <v-col cols="12" class="pb-0">
                  <div class="">RG <span class="red--text">*</span></div>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    clearable
                    outlined
                    required
                    placeholder="000.000.00"
                    v-mask="'#.###.###'"
                    v-model="dataForm.rg"
                    :rules="rgRules"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="pb-0" cols="12" md="4" lg="4">
              <v-row>
                <v-col cols="12" class="pb-0">
                  <div class="">Celular <span class="red--text">*</span></div>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    clearable
                    outlined
                    required
                    placeholder="(00) 0 0000-0000"
                    v-mask="'(##) # ####-####'"
                    v-model="dataForm.phone"
                    :rules="[(v) => !!v || 'Telefone é obrigatório']"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="pb-0" cols="12" md="4" lg="4">
              <v-row>
                <v-col cols="12" class="pb-0">
                  <div class="">Sexo <span class="red--text">*</span></div>
                </v-col>
                <v-col cols="12">
                  <v-select
                    clearable
                    outlined
                    required
                    :items="sexItems"
                    placeholder="Selecione"
                    v-model="dataForm.sex"
                    :rules="[(v) => !!v || 'Sexo é obrigatório']"
                  ></v-select>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="pb-0" cols="12" md="4" lg="4">
              <v-row>
                <v-col cols="12" class="pb-0">
                  <div class="">
                    Estado civil <span class="grey--text"> * opcional</span>
                  </div>
                </v-col>
                <v-col cols="12">
                  <v-select
                    clearable
                    outlined
                    :items="maritalStatus"
                    placeholder="Selecione"
                    v-model="dataForm.marital_status"
                  ></v-select>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="pb-0" cols="12">
              <v-row>
                <v-col cols="12" class="pb-0">
                  <div class="">
                    Comentários
                    <!-- <span class="red--text">*</span> -->
                  </div>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    clearable
                    outlined
                    placeholder="Escreva um comentário"
                    v-model="dataForm.comments"
                    counter="500"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="$emit('update:step', Number(step) + 1)" class="primary">
          Próximo
        </v-btn>
      </v-card-actions>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: ["data", "step", "formValidate", "loading"],
  data: () => ({
    date: " ",
    checkbox: false,
    birthDate: null,
    modal: false,
    nameRules: [(v) => !!v || "Nome é obrigatório"],
    birthDateRules: [(v) => !!v || "Data de nascimento é obrigatório"],
    cpfRules: [(v) => !!v || "CPF é obrigatório"],
    rgRules: [(v) => !!v || "RG é obrigatório"],
    ufRules: [(v) => !!v || "UF é obrigatório"],
    addressRules: [(v) => !!v || "Endereço é obrigatório"],
    cityRules: [(v) => !!v || "Cidade é obrigatório"],
    cepRules: [(v) => !!v || "CEP é obrigatório"],
    numberRules: [(v) => !!v || "Número é obrigatório"],
    districtRules: [(v) => !!v || "Bairro é obrigatório"],
    emailRules: [(v) => !!v || "Email é obrigatório"],
    phoneRules: [(v) => !!v || "Telefone é obrigatório"],
    stateRules: [(v) => !!v || "Estado é obrigatório"],
    maritalStatus: [
      {
        text: "Solteiro(a)",
        value: "single",
      },
      {
        text: "Casado(a)",
        value: "married",
      },
      {
        text: "União estável",
        value: "stable_union",
      },
      {
        text: "Divorciado(a)",
        value: "divorced",
      },
      {
        text: "Viúvo(a)",
        value: "widower",
      },
      {
        text: "Prefiro não responder",
        value: "prefer_not_to_answer",
      },
    ],
    sexItems: [
      {
        text: "Feminino",
        value: "0",
      },
      {
        text: "Masculino",
        value: "1",
      },
    ],
  }),
  mounted() {
    this.saveCurrentDate();
  },
  methods: {
    blockNumber(e) {
      if (e.keyCode > 47 && e.keyCode < 58) e.preventDefault();
    },
    // saveAgeFormatted(birthDate) {
    //   var currentDate = new Date();
    //   this.dataForm.age = currentDate.getFullYear() - birthDate.slice(0, 4);
    // },
    saveCurrentDate() {
      var date = new Date();
      var day = String(date.getDate()).padStart(2, "0");
      var mounth = String(date.getMonth() + 1).padStart(2, "0");
      var year = date.getFullYear();
      this.date = year + "-" + mounth + "-" + day;
      // console.log(this.date);
    },
  },
  computed: {
    dataForm: {
      get() {
        return this.data || {};
      },
      set(generalData) {
        return this.$emit("update:data", generalData);
      },
    },
  },
  watch: {},
};
</script>

<style></style>