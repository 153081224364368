var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-card',{staticClass:"elevation-0"},[_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"text-h5 pb-0",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.data ? _vm.data[_vm.step - 1].type : _vm.formUpdate[0] ? _vm.formUpdate[0].name : "Nenhum formulário disponível")+" ")]),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12"}},[_c('v-subheader',{staticClass:"pa-0 ma-0 grey--text"},[_vm._v(" "+_vm._s(_vm.$route.params.form_application_id ? "Edição de anamnese" : "Nova anamnese")+" ")])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-stepper',{key:_vm.data ? _vm.data.length : _vm.form.length,staticClass:"elevation-0",attrs:{"alt-labels":""},model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c('v-stepper-header',{staticClass:"d-flex justify-start align-center elevation-0",staticStyle:{"background":"#f3f3f3"}},_vm._l((_vm.data ? _vm.data : _vm.formUpdate),function(item,index){return _c('div',{key:index},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-stepper-step',{key:index,staticClass:"pointer",class:{ hover: hover },attrs:{"step":index + 1,"complete":_vm.step > index + 1,"color":_vm.step > index + 1 ? 'green' : '#838181'},on:{"click":function($event){_vm.step = index + 1}}},[_vm._v(" "+_vm._s(_vm.data ? item.type : item.name)+" ")])]}}],null,true)})],1)}),0),_c('v-stepper-items',[_c('v-form',{ref:"formAnamneseAnswer",attrs:{"lazy-validation":""},model:{value:(_vm.validForm),callback:function ($$v) {_vm.validForm=$$v},expression:"validForm"}},_vm._l((_vm.data ? _vm.data : _vm.form),function(item,index){return _c('v-stepper-content',{key:index,staticClass:"elevation-0",attrs:{"step":index + 1}},[_c('v-card',[_c('v-card-text',{staticClass:"pl-0"},[_c('v-row',_vm._l((item.form_items),function(formItem,key){return _c('v-col',{key:key,staticClass:"pb-0 pt-0",attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{staticClass:"text-h6 black--text pb-0",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(formItem.name .replace("?", "") .replace(":", ""))+": "),_c('span',{class:{
                                  'red--text': formItem.required,
                                  'grey--text': !formItem.required,
                                }},[_vm._v("*")])]),(formItem.kind === 'radio')?_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[(
                                  _vm.answersAsHash[formItem.id] &&
                                  _vm.answersAsHash[formItem.id].config
                                )?_c('v-radio-group',{attrs:{"row":""},model:{value:(_vm.answersAsHash[formItem.id].content),callback:function ($$v) {_vm.$set(_vm.answersAsHash[formItem.id], "content", $$v)},expression:"answersAsHash[formItem.id].content"}},_vm._l((_vm.answersAsHash[
                                    formItem.id
                                  ].config.items),function(item,index){return _c('v-radio',{key:index,attrs:{"label":item.text,"value":item.text}})}),1):_vm._e()],1):_vm._e(),(formItem.kind === 'string')?_c('v-col',{attrs:{"cols":"12"}},[(_vm.answersAsHash[formItem.id])?_c('v-text-field',{attrs:{"outlined":"","dense":"","placeholder":"Responder","type":formItem.kind,"rules":formItem.required
                                    ? [function (v) { return !!v || 'Campo obrigatório'; }]
                                    : [],"required":formItem.required,"counter":formItem.counter},model:{value:(_vm.answersAsHash[formItem.id].content),callback:function ($$v) {_vm.$set(_vm.answersAsHash[formItem.id], "content", $$v)},expression:"answersAsHash[formItem.id].content"}}):_vm._e()],1):_vm._e(),(formItem.kind === 'text')?_c('v-col',{attrs:{"cols":"12"}},[(_vm.answersAsHash[formItem.id])?_c('v-textarea',{attrs:{"outlined":"","dense":"","placeholder":"Responder","rules":formItem.required
                                    ? [function (v) { return !!v || 'Campo obrigatório'; }]
                                    : [],"required":formItem.required,"counter":formItem.counter},model:{value:(_vm.answersAsHash[formItem.id].content),callback:function ($$v) {_vm.$set(_vm.answersAsHash[formItem.id], "content", $$v)},expression:"answersAsHash[formItem.id].content"}}):_vm._e()],1):_vm._e(),(formItem.kind === 'number')?_c('v-col',{attrs:{"cols":"12"}},[(_vm.answersAsHash[formItem.id])?_c('v-text-field',{attrs:{"outlined":"","dense":"","placeholder":"Responder","type":formItem.kind,"required":formItem.required,"counter":formItem.counter,"rules":formItem.required
                                    ? [function (v) { return !!v || 'Campo obrigatório'; }]
                                    : []},model:{value:(_vm.answersAsHash[formItem.id].content),callback:function ($$v) {_vm.$set(_vm.answersAsHash[formItem.id], "content", $$v)},expression:"answersAsHash[formItem.id].content"}}):_vm._e()],1):_vm._e(),(formItem.kind === 'date')?_c('v-col',{attrs:{"cols":"12"}},[(_vm.answersAsHash[formItem.id])?_c('v-menu',{ref:"datePickerModal",refInFor:true,attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                    var on = ref.on;
                                    var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"placeholder":"MM/DD/YYYY","hint":"MM/DD/YYYY format","persistent-hint":"","prepend-inner-icon":"mdi-calendar","outlined":"","dense":""},on:{"blur":function($event){_vm.answersAsHash[formItem.id].content =
                                        _vm.parseDate(
                                          _vm.answersAsHash[formItem.id].content
                                        )}},model:{value:(
                                      _vm.answersAsHash[formItem.id].content
                                    ),callback:function ($$v) {_vm.$set(_vm.answersAsHash[formItem.id], "content", $$v)},expression:"\n                                      answersAsHash[formItem.id].content\n                                    "}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.datePickerModal),callback:function ($$v) {_vm.datePickerModal=$$v},expression:"datePickerModal"}},[(_vm.answersAsHash[formItem.id])?_c('v-date-picker',{attrs:{"value":_vm.answersAsHash[formItem.id] &&
                                    _vm.answersAsHash[formItem.id].content,"no-title":""},on:{"input":function($event){_vm.formItemAnswers.push({
                                      form_item_id: formItem.id,
                                      form_application_id:
                                        _vm.$route.params.form_application_id,
                                      user_id: _vm.$route.params.id,
                                      content: $event || '',
                                    });
                                    _vm.datePickerModal = false;}}}):_vm._e()],1):_vm._e()],1):_vm._e(),(formItem.kind === 'multiselect')?_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-select',{directives:[{name:"show",rawName:"v-show",value:(_vm.answersAsHash[formItem.id]),expression:"answersAsHash[formItem.id]"}],attrs:{"outlined":"","dense":"","placeholder":"Selecione","items":_vm.answersAsHash[formItem.id] &&
                                  _vm.answersAsHash[formItem.id].config.items,"value":_vm.answersAsHash[formItem.id] &&
                                  _vm.answersAsHash[formItem.id].content_multiple,"multiple":"","rules":formItem.required
                                    ? [function (v) { return !!v || 'Campo obrigatório'; }]
                                    : [],"required":formItem.required},on:{"input":function($event){return _vm.updateSelectResponses($event, formItem.id)}},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-row',{staticClass:"pa-2"},[_c('v-col',{attrs:{"cols":"10","sm":"11"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","placeholder":"Novo item"},model:{value:(_vm.newMultiselectItem),callback:function ($$v) {_vm.newMultiselectItem=$$v},expression:"newMultiselectItem"}})],1),_c('v-col',{attrs:{"cols":"2","sm":"1"}},[_c('v-btn',{attrs:{"width":"100%","text":""},on:{"click":function($event){return _vm.addItemMultselect(
                                            _vm.newMultiselectItem,
                                            formItem.id
                                          )}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-plus")])],1)],1)],1)]},proxy:true}],null,true)})],1):_vm._e(),(formItem.kind === 'imc')?_c('v-col',{attrs:{"cols":"12"}},[_c('imcAnswer',{on:{"content":function($event){return _vm.bmiResult(
                                    $event,
                                    _vm.answersAsHash[formItem.id]
                                  )}}})],1):_vm._e()],1)],1)}),1)],1),_c('v-card-actions',{},[(_vm.step > 1)?_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.step = _vm.step - 1}}},[_vm._v("Voltar")]):_vm._e(),_c('v-spacer'),_c('v-btn',{staticClass:"primary",attrs:{"loading":_vm.loading,"disabled":_vm.data
                            ? _vm.step !== _vm.data.length
                              ? false
                              : !_vm.validForm
                            : _vm.step !== _vm.form.length
                            ? false
                            : !_vm.validForm},on:{"click":function($event){return _vm.onAnswerAnamnesis()}}},[_vm._v(" "+_vm._s(_vm.step !== _vm.data.length ? "Próximo" : "Salvar")+" ")])],1)],1)],1)}),1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }